import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
// For some reason the netlify intermittently fails when sprancer-shared is imported:
//   9:21:36 PM: error Generating JavaScript bundles failed
//   9:21:36 PM: Can't resolve 'date-fns' in '/opt/build/repo/shared/dist'
//import { constants } from "sprancer-shared"
//const { SITE_NAME, SUPPORT_EMAIL } = constants

// So for now, just hardcode the constants.
const SITE_NAME = "sprancer.com"
const SUPPORT_EMAIL = "support@sprancer.com"
const COMPANY_NAME = "Sprancer LLC"

const PrivacyPage = () => (
  <Layout>
    <SEO title="Privacy Policy" />
    <div className="mt-5 container lander">
      <p>Effective date: 02/07/2020</p>
      <p>
        {COMPANY_NAME} (“us”, “we”, or “our”) operates {SITE_NAME} (hereinafter
        referred to as “Service”).
      </p>
      <p>
        Our Privacy Policy governs your visit to {SITE_NAME}, and explains how
        we collect, safeguard and disclose information that results from your
        use of our Service.
      </p>
      <p>
        We use your data to provide and improve Service. By using Service, you
        agree to the collection and use of information in accordance with this
        policy. Unless otherwise defined in this Privacy Policy, the terms used
        in this Privacy Policy have the same meanings as in our Terms and
        Conditions.
      </p>
      <p>
        Our Terms and Conditions (“Terms”) govern all use of our Service and
        together with the Privacy Policy constitutes your agreement with us
        (“agreement”).
      </p>

      <h2>Defintions</h2>
      <p>
        SERVICE means the {SITE_NAME} website operated by {COMPANY_NAME}.
      </p>
      <p>
        PERSONAL DATA means data about a living individual who can be identified
        from those data (or from those and other information either in our
        possession or likely to come into our possession).
      </p>
      <p>
        USAGE DATA is data collected automatically either generated by the use
        of Service or from Service infrastructure itself (for example, the
        duration of a page visit).
      </p>
      <p>
        COOKIES are small files stored on your device (computer or mobile
        device).
      </p>
      <p>
        DATA CONTROLLER means a natural or legal person who (either alone or
        jointly or in common with other persons) determines the purposes for
        which and the manner in which any personal data are, or are to be,
        processed. For the purpose of this Privacy Policy, we are a Data
        Controller of your data.
      </p>
      <p>
        DATA PROCESSORS (OR SERVICE PROVIDERS) means any natural or legal person
        who processes the data on behalf of the Data Controller. We may use the
        services of various Service Providers in order to process your data more
        effectively.
      </p>
      <p>
        DATA SUBJECT is any living individual who is the subject of Personal
        Data.
      </p>
      <p>
        THE USER is the individual using our Service. The User corresponds to
        the Data Subject, who is the subject of Personal Data.
      </p>

      <h2>Information Collection and Use</h2>
      <p>
        We collect several different types of information for various purposes
        to provide and improve our Service to you.
      </p>

      <h2>Types of Data Collected</h2>

      <h3>Personal Data</h3>
      <p>
        While using our Service, we may ask you to provide us with certain
        personally identifiable information that can be used to contact or
        identify you (“Personal Data”). Personally identifiable information may
        include, but is not limited to:
      </p>
      <ul>
        <li>Email address</li>
        <li>Phone number</li>
      </ul>

      <h3>Cookies and Usage Data</h3>
      <p>
        We may use your Personal Data to contact you with newsletters, marketing
        or promotional materials and other information that may be of interest
        to you. You may opt out of receiving any, or all, of these
        communications from us by following the unsubscribe link or by emailing
        at {SUPPORT_EMAIL}.
      </p>

      <h3>Usage Data</h3>
      <p>
        We may also collect information that your browser sends whenever you
        visit our Service or when you access Service by or through a mobile
        device (“Usage Data”).
      </p>
      <p>
        This Usage Data may include information such as your computer's Internet
        Protocol address (e.g. IP address), browser type, browser version, the
        pages of our Service that you visit, the time and date of your visit,
        the time spent on those pages, unique device identifiers and other
        diagnostic data.
      </p>
      <p>
        When you access Service with a mobile device, this Usage Data may
        include information such as the type of mobile device you use, your
        mobile device unique ID, the IP address of your mobile device, your
        mobile operating system, the type of mobile Internet browser you use,
        unique device identifiers and other diagnostic data.
      </p>

      <h3>Tracking Cookies Data</h3>
      <p>
        We use cookies and similar tracking technologies to track the activity
        on our Service and we hold certain information.
      </p>
      <p>
        Cookies are files with a small amount of data which may include an
        anonymous unique identifier. Cookies are sent to your browser from a
        website and stored on your device. Other tracking technologies are also
        used such as beacons, tags and scripts to collect and track information
        and to improve and analyze our Service.
      </p>
      <p>
        You can instruct your browser to refuse all cookies or to indicate when
        a cookie is being sent. However, if you do not accept cookies, you may
        not be able to use some portions of our Service.
      </p>

      <h4>Examples of Cookies we use:</h4>
      <ul>
        <li>Session Cookies: We use Session Cookies to operate our Service.</li>
        <li>
          Preference Cookies: We use Preference Cookies to remember your
          preferences and various settings.
        </li>
        <li>
          Security Cookies: We use Security Cookies for security purposes.
        </li>
        <li>
          Advertising Cookies: Advertising Cookies are used to serve you with
          advertisements that may be relevant to you and your interests.
        </li>
      </ul>

      <h2>Use of Data</h2>
      <p>{COMPANY_NAME} uses the collected data for various purposes:</p>
      <ul>
        <li>to provide and maintain our Service;</li>
        <li>to notify you about changes to our Service;</li>
        <li>
          to allow you to participate in interactive features of our Service
          when you choose to do so;
        </li>
        <li>to provide customer support;</li>
        <li>
          to gather analysis or valuable information so that we can improve our
          Service;
        </li>
        <li>to monitor the usage of our Service;</li>
        <li>to detect, prevent and address technical issues;</li>
        <li>to fulfill any other purpose for which you provide it;</li>
        <li>
          to carry out our obligations and enforce our rights arising from any
          contracts entered into between you and us, including for billing and
          collection;
        </li>
        <li>
          to provide you with notices about your account and/or subscription,
          including expiration and renewal notices, email-instructions, etc.;
        </li>
        <li>
          to provide you with news, special offers and general information about
          other goods, services and events which we offer that are similar to
          those that you have already purchased or enquired about unless you
          have opted not to receive such information;
        </li>
        <li>
          in any other way we may describe when you provide the information;
        </li>
        <li>for any other purpose with your consent.</li>
      </ul>

      <h2>Retention of Data</h2>
      <p>
        We will retain your Personal Data only for as long as is necessary for
        the purposes set out in this Privacy Policy. We will retain and use your
        Personal Data to the extent necessary to comply with our legal
        obligations (for example, if we are required to retain your data to
        comply with applicable laws), resolve disputes, and enforce our legal
        agreements and policies.
      </p>
      <p>
        We will also retain Usage Data for internal analysis purposes. Usage
        Data is generally retained for a shorter period, except when this data
        is used to strengthen the security or to improve the functionality of
        our Service, or we are legally obligated to retain this data for longer
        time periods.
      </p>

      <h2>Transfer of Data</h2>
      <p>
        Your information, including Personal Data, may be transferred to – and
        maintained on – computers located outside of your state, province,
        country or other governmental jurisdiction where the data protection
        laws may differ from those of your jurisdiction.
      </p>
      <p>
        If you are located outside United States and choose to provide
        information to us, please note that we transfer the data, including
        Personal Data, to United States and process it there.
      </p>
      <p>
        Your consent to this Privacy Policy followed by your submission of such
        information represents your agreement to that transfer.
      </p>
      <p>
        {COMPANY_NAME} will take all the steps reasonably necessary to ensure
        that your data is treated securely and in accordance with this Privacy
        Policy and no transfer of your Personal Data will take place to an
        organisation or a country unless there are adequate controls in place
        including the security of your data and other personal information.
      </p>

      <h2>Disclosure of Data</h2>
      <p>
        We may disclose personal information that we collect, or you provide:
      </p>
      <h3>Disclosure for Law Enforcement.</h3>
      <p>
        Under certain circumstances, we may be required to disclose your
        Personal Data if required to do so by law or in response to valid
        requests by public authorities.
      </p>
      <h3>Business Transaction.</h3>
      <p>
        If we or our subsidiaries are involved in a merger, acquisition or asset
        sale, your Personal Data may be transferred.
      </p>
      <h3>Other cases.</h3>
      <p>We may disclose your information also:</p>
      <ul>
        <li>
          to contractors, service providers, and other third parties we use to
          support our business;
        </li>
        <li>to fulfill the purpose for which you provide it;</li>
        <li>with your consent in any other cases;</li>
        <li>
          if we believe disclosure is necessary or appropriate to protect the
          rights, property, or safety of the Company, our customers, or others.
        </li>
      </ul>

      <h2>Security of Data</h2>
      <p>
        The security of your data is important to us but remember that no method
        of transmission over the Internet or method of electronic storage is
        100% secure. While we strive to use commercially acceptable means to
        protect your Personal Data, we cannot guarantee its absolute security.
      </p>

      <h2>
        Your Data Protection Rights Under General Data Protection Regulation
        (GDPR)
      </h2>
      <p>
        If you are a resident of the European Union (EU) and European Economic
        Area (EEA), you have certain data protection rights, covered by GDPR. –
        See more at https://eur-lex.europa.eu/eli/reg/2016/679/oj
      </p>
      <p>
        We aim to take reasonable steps to allow you to correct, amend, delete,
        or limit the use of your Personal Data.
      </p>
      <p>
        If you wish to be informed what Personal Data we hold about you and if
        you want it to be removed from our systems, please email us at{" "}
        {SUPPORT_EMAIL}.
      </p>
      <p>
        In certain circumstances, you have the following data protection rights:
      </p>
      <ul>
        <li>
          the right to access, update or to delete the information we have on
          you;
        </li>
        <li>
          the right of rectification. You have the right to have your
          information rectified if that information is inaccurate or incomplete;
        </li>
        <li>
          the right to object. You have the right to object to our processing of
          your Personal Data;
        </li>
        <li>
          the right of restriction. You have the right to request that we
          restrict the processing of your personal information;
        </li>
        <li>
          the right to data portability. You have the right to be provided with
          a copy of your Personal Data in a structured, machine-readable and
          commonly used format;
        </li>
        <li>
          the right to withdraw consent. You also have the right to withdraw
          your consent at any time where we rely on your consent to process your
          personal information;
        </li>
      </ul>
      <p>
        Please note that we may ask you to verify your identity before
        responding to such requests. Please note, we may not able to provide
        Service without some necessary data.
      </p>
      <p>
        You have the right to complain to a Data Protection Authority about our
        collection and use of your Personal Data. For more information, please
        contact your local data protection authority in the European Economic
        Area (EEA).
      </p>

      <h2>
        Your Data Protection Rights under the California Privacy Protection Act
        (CalOPPA)
      </h2>
      <p>
        CalOPPA is the first state law in the nation to require commercial
        websites and online services to post a privacy policy. The law’s reach
        stretches well beyond California to require a person or company in the
        United States (and conceivable the world) that operates websites
        collecting personally identifiable information from California consumers
        to post a conspicuous privacy policy on its website stating exactly the
        information being collected and those individuals with whom it is being
        shared, and to comply with this policy. – See more at:
        https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/
      </p>
      <p>According to CalOPPA we agree to the following:</p>
      <ul>
        <li>users can visit our site anonymously;</li>
        <li>
          our Privacy Policy link includes the word “Privacy”, and can easily be
          found on the page specified above on the home page of our website;
        </li>
        <li>
          users will be notified of any privacy policy changes on our Privacy
          Policy Page;
        </li>
        <li>
          users are able to change their personal information by emailing us at{" "}
          {SUPPORT_EMAIL}.
        </li>
      </ul>
      <h3>Our Policy on “Do Not Track” Signals:</h3>
      <p>We do not honor Do Not Track signals</p>

      <h2>Service Providers</h2>
      <p>
        We may employ third party companies and individuals to facilitate our
        Service (“Service Providers”), provide Service on our behalf, perform
        Service-related services or assist us in analysing how our Service is
        used.
      </p>
      <p>
        These third parties have access to your Personal Data only to perform
        these tasks on our behalf and are obligated not to disclose or use it
        for any other purpose.
      </p>

      <h2>Analytics</h2>
      <p>
        We may use third-party Service Providers to monitor and analyze the use
        of our Service.
      </p>

      <h2>CI/CD tools</h2>
      <p>
        We may use third-party Service Providers to automate the development
        process of our Service.
      </p>

      <h2>Links to Other Sites</h2>
      <p>
        Our Service may contain links to other sites that are not operated by
        us. If you click a third party link, you will be directed to that third
        party's site. We strongly advise you to review the Privacy Policy of
        every site you visit.
      </p>
      <p>
        We have no control over and assume no responsibility for the content,
        privacy policies or practices of any third party sites or services.
      </p>

      <h2>Children's Privacy</h2>
      <p>
        Our Services are not intended for use by children under the age of 18
        (“Child” or “Children”).
      </p>
      <p>
        We do not knowingly collect personally identifiable information from
        Children under 18. If you become aware that a Child has provided us with
        Personal Data, please contact us. If we become aware that we have
        collected Personal Data from Children without verification of parental
        consent, we take steps to remove that information from our servers.
      </p>

      <h2>Changes to This Privacy Policy</h2>
      <p>
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page.
      </p>
      <p>
        We will let you know via email and/or a prominent notice on our Service,
        prior to the change becoming effective and update “effective date” at
        the top of this Privacy Policy.
      </p>
      <p>
        You are advised to review this Privacy Policy periodically for any
        changes. Changes to this Privacy Policy are effective when they are
        posted on this page.
      </p>

      <h2>Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, please contact us
        by email: {SUPPORT_EMAIL}.
      </p>
    </div>
  </Layout>
)

export default PrivacyPage
